import * as React from "react";
import Button from "react-bootstrap/Button";

export default class StatParams extends React.Component{
    constructor(props) {
        super(props);
        this.addStatisticParam = this.addStatisticParam.bind(this);

        this.state = {
            isLoaded: false,
            statParams: []
        }
    }

    addStatisticParam(e){
        this.props.showModal(e.target.value, this.props.teamId);
    }

    render() {
        const statParams = this.props.statParams;
        const paramsList = Object.keys(statParams).map((key) => {
            return(
                <>{ parseInt(statParams[key].personRelatedParam)===1 && parseInt(statParams[key].active)===1 &&
                    <Button key={key} variant="orange" value={statParams[key].id}
                            onClick={this.addStatisticParam} className="px-1 px-md-2"
                    >{statParams[key].name}</Button>
                }
                </>
            )
        })

        return(
            <div className="stat-params">
                {paramsList}
            </div>
        )
    }
}
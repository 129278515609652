import _ from "lodash";

export default class EventLogicCalculator{
   /* blockedPersons=[];
    transformingStatParams=[];*/
    setParamsDictionary(statParams){
        this.statParamsDict = statParams;
    }
    calculate(eventStatParamsLog, statParams, teamAid){

        let scoreA=0;
        let scoreB=0;
        this.blockedPersons=[];
        this.transformingStatParams=[];
        _.forEach(eventStatParamsLog, function(value) {
            if(typeof statParams[value.typeId] === 'undefined') {
                return true;
            }

            const {influenceToOwnScore, influenceToOpponentScore, disqualifiesPerson, transformingNumber, transformingTypeId} = statParams[value.typeId];
            if(value.teamId===teamAid)
            {
                scoreA += parseInt(influenceToOwnScore);
                scoreB += parseInt(influenceToOpponentScore);
            }
            else
            {
                scoreB += parseInt(influenceToOwnScore);
                scoreA += parseInt(influenceToOpponentScore);
            }

            if(disqualifiesPerson && value.personId){
                this.blockedPersons.push({personId: value.personId, typeId: value.typeId})
            }

            this._checkTransformation(value, transformingNumber, transformingTypeId);

        }.bind(this));
        return [{teamAScore:scoreA, teamBScore:scoreB}, this.blockedPersons];
    }

    _checkTransformation(eventStatParam, transformingNumber, transformingTypeId){
        if(parseInt(transformingNumber)>0) {
            this.transformingStatParams.push(eventStatParam);

            if(this._shouldTransformationBeApplied(eventStatParam.personId, eventStatParam.typeId, transformingNumber)){
                this.blockedPersons.push({personId: eventStatParam.personId, typeId: transformingTypeId})
            }
        }
    }

    _shouldTransformationBeApplied(personId, statParamId, numberForTransformation){
        const transformingActions = this.transformingStatParams.filter((value)=>{
            return parseInt(value.personId) === parseInt(personId) && parseInt(value.typeId) === parseInt(statParamId)
        })

        return transformingActions.length===parseInt(numberForTransformation);
    }


    getTransformedStatParam(statParam, eventStatParamsLog) {
        const {transformingNumber, transformingTypeId} = this.statParamsDict[statParam.typeId];
        if (!transformingNumber)
            return [statParam, []];

        const statParamsToTransform = _.filter(eventStatParamsLog, function (v, k) {
            return v.typeId === statParam.typeId && v.personId === statParam.personId && v.id !== statParam.id;
        })
        if(statParamsToTransform.length+1 >= transformingNumber){
            return this._performTransformation(statParam, transformingTypeId, statParamsToTransform);
        }

        return [statParam, []];
    }
    _performTransformation(statParam, statParamIdToTransform, statParamsLedToTransformation){
        const paramsToRemove = statParamsLedToTransformation.slice();
        paramsToRemove.push(statParam);
        const transformedStatParams = paramsToRemove.map(v=>{return {typeId:v.typeId, value:v.value}})
        let dumpOfRemoved = JSON.stringify(transformedStatParams);
        const transformedStatParam = {...statParam, ...{typeId:statParamIdToTransform, transformationLog:dumpOfRemoved}}

        return [transformedStatParam, statParamsLedToTransformation];
    }

}